@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: inherit;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  line-height: 1.4rem;
}

.container {
  max-width: 1140px !important;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #fafbfc 0%, #dee5eb 30%, #537791 100%);

  /* background: linear-gradient(180deg, #c6d2dd 0%, #4a6f94 100%); */
  position: relative;
}

.primary-text-color {
  color: #3b638c;
}

.question-text {
  font-weight: 700;
  color: #000;
}

.custom-primary-button {
  background: #f9df81;
  border-radius: 8px;
  width: 100%;
  color: #202020;
  font-size: 1rem;
  border: none;
  font-weight: 700;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.secondary-button {
  background: #1b2e48;
  border-radius: 50px;
  width: 100%;
  color: #fff;
  border: none;
  font-weight: 700;
}

.register-button {
  background: #1b2e48;
  border-radius: 30px;
  width: 50%;
  color: #fff;
  border: none;
  font-weight: 700;
  padding: 13px 0px 13px 0px;
}

.large-radio .ant-radio {
  transform: scale(1.4); /* Increase the scale value as needed */
  margin-left: 1.5rem;
}

.radio-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.large-radio .ant-radio-wrapper {
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-form-item
  .ant-form-item-explain-error {
  margin-left: 1rem;
}

.radio-container .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.radio-container .ant-radio-input {
  width: 20px;
  height: 20px;
}

.correct-option .ant-radio {
  color: green;
}

.correct-option .ant-radio .ant-radio-inner {
  border-color: green;
}

.correct-option .ant-radio-checked .ant-radio-inner {
  background-color: green;
  border-color: green;
}

.wrong-option .ant-radio {
  color: red;
}

.wrong-option .ant-radio .ant-radio-inner {
  border-color: red;
}

.wrong-option .ant-radio-checked .ant-radio-inner {
  background-color: red;
  border-color: red;
}

.default-option .ant-radio {
  color: blue;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-scrollbar {
  position: relative;
  width: 5rem;
  height: 0.2rem;
  border-radius: 50px;
  margin-top: 10px;
  color: rgba(217, 217, 217, 1);
  background-color: rgba(217, 217, 217, 1);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.f500 {
  font-weight: 500;
}

.f600 {
  font-weight: 600;
}

.f700 {
  font-weight: 700;
}
.f800 {
  font-weight: 800;
}
.f900 {
  font-weight: 900;
}

.fsize1 {
  font-size: 1rem;
}

.fsize2 {
  font-size: 2rem;
}

.fsize3 {
  font-size: 3rem;
}

.bg-yellow {
  background-color: #f0d474;
  border-radius: 2rem;
  width: 40%;
  text-align: center;
  margin: 0.5rem 0rem;
}

.bg-yellow-full-width {
  background-color: #f0d474;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.custom-card {
  background-color: #fff;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.complication-img {
  flex: 20%;
}

.complication-img-7 {
  width: 40%;
}

.whatisdlp-img {
  width: 20%;
}

.whatisdlp-img-bigger {
  width: 23%;
}

.dietary-img {
  width: 22%;
}

.dietary-img-common {
  width: 20%;
}

.dietary-img-bigger {
  width: 23%;
}

.dietary-img-special {
  width: 18.5%;
}

.image-test-yourself {
  background-image: url("./images/quiz-1.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 30%;
  background-position: center;

}

.height-full {
  height: 100%;
}

.text-deco-none {
  text-decoration: none;
}

.text08 {
  font-size: 0.6rem;
  line-height: 130%;
}

.card {
  background-color: unset !important;
  border: unset !important;
}

.question-container {
  background-color: white;
  margin: 1% 0;
  padding: 2%;
  border-radius: 15.77px;
}

.whatdlp-desc {
  font-size: 0.9rem;
}



@media (max-width: 768px) {
  .register-button {
    width: 100%;
  }

  .dietary-img {
    width: 35%;
  }
  .dietary-img-common {
    width: 32%;
  }
  .dietary-img-bigger {
    width: 35%;
  }

  .dietary-img-special {
    width: 31%;
  }

  .whatisdlp-img {
    width: 33%;
  }

  .whatisdlp-img-bigger {
    width: 36%;
  }

  .complication-img {
    width: 25%;
  }

  .complication-img-7 {
    width: 40%;
  }

  .bg-yellow {
    width: 60%;
    margin: 0rem;
  }
}


@media (min-width: 766px) {
  .secondary-button {
    padding: 10px !important;
    font-size: 16px !important;
  }
  .butone-container {
    width: 60%;
    font-size: 1.5rem;
    gap: 20px
  }

  .image-test-yourself {
    background-image: url("./images/quiz-desktop.svg");
    height: 90%;
    width: 40%;
  
  }

  .bg-yellow-full-width {
    border-radius: 1.5rem;
    height: 35vh
  }
  
  .actual-butone a button {
    padding: 18px 22px !important;
    width: 65%;
    font-size: 80% !important;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .text-test-yourself {
    font-size: 100%;
  }

  .arrow-test-yourself {
    height: 20px;
  }

  .whatdlp-desc {
    font-size: 1rem;
  }

  .text08 {
    font-size: 0.9rem;
  }
}

@media (min-width: 1000px) {
  .complication-img {
    width: 15% !important;
    margin: 1% 5% !important;
    flex: unset;
  }

  .complication-img-7 {
    width: 23% !important;
  }
}
